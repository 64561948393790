<template>


  <div class="encyclopedia w-100">
<!--    <JayaTitle classType="page-title" moreInfo="Obtenez rapidement des conseils pour faire de votre jardin un harve de paix pour la biodiversité" titleName="Biodiversité" class="mt-1"/>-->
    <iframe src="https://baptisteamare.github.io/Labo_versite/" style="width: 100%; height: 92vh;" frameBorder="0" scrolling="no" @load="stopLoading"/>

  </div>


</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import PlantSearch from "@/components/plants/PlantSearch.vue"
import AnimalSearch from "@/components/animals/AnimalSearch.vue"
import {BTab, BTabs} from 'bootstrap-vue'
export default {
  name: 'Encyclopedia',
  components: {
    JayaTitle,
    BTabs,
    BTab,
    PlantSearch,
    AnimalSearch,
  },
  methods: {
    scroll(event) {
      if (this.$refs.encyclopediaTabs.currentTab === 0) {
        this.$refs.plantSearch.scroll(event)
      } else if (this.$refs.encyclopediaTabs.currentTab === 1) {
        this.$refs.animalSearch.scroll(event)
      }
    },
    stopLoading() {
      this.$loading(false)
    }
  },
  mounted() {
    this.$loading(true)
    this.$store.commit("app/SET_TITLE", "Biodiv' Quiz")
  }

}
</script>
